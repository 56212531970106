import React from "react";
import {Link} from "gatsby";
import styles from './box.module.scss';

export default function Box({icon, title, url}) {
    return <Link to={'/' + url}>
        <div className={styles.box}>
            <div className={styles.icon}>
                <img src={`/images/${icon}`} alt="icon" />
            </div>
            <div className={styles.description}>
                <h2 dangerouslySetInnerHTML={{__html: title}}></h2>
            </div>
        </div>
    </Link>
}
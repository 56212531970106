import React, { useEffect } from "react";
import styles from './boxes.module.scss'
import Box from "./box";

export default function Boxes({data}) {
    const refWrapper = React.createRef();
    useEffect(() => {
       let itemsWidth = 0;
       for (let item of refWrapper.current.children) {
           itemsWidth += item.getBoundingClientRect().width;
       }
       const width = itemsWidth - window.innerWidth;
       if(width > 0) {
            refWrapper.current.scrollLeft = width / 2;
        }
    });
    return <div ref={refWrapper} className={styles.wrapper}>
            {data.map((value, key) => <Box key={key} icon={value.icon} title={value.text} url={value.url} />)}
        </div>
}
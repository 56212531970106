import React, {useState} from "react";
import styles from "./item.module.scss";
import {href} from "../../../../helpers/Link";
import Scroll from "react-scroll";

export default function Button({data, type, link}) {
  const [, handleActive] = useState(`${data[0].text}`);
  if (link[0] === "#") {
    return (
      <Scroll.Link
        to={link ? href(link) : ""}
        className={`${styles.button} ${styles[type]}`}
        spy
        smooth
        offset={-80}
        onClick={() => {
          handleActive(link);
        }}
      >
        {data}
      </Scroll.Link>
    );
  }

  return (
    <a href={link} className={`${styles.button} ${styles[type]}`}>
      {data}
    </a>
  );
}

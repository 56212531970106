import React from "react";
import {Link} from "gatsby";
import styles from './navigation.module.scss';
import logo_image from './../../../static/images/STS_Logos_RGB_Additional_Transp.png';

export default function Navigation(props) {
    return <div className={styles.header}>
        <div className={styles.shape}>
            <a href='https://www.sts.pl/'>
                <img className={styles.logo} alt="sts" src={logo_image}/>
            </a>
        </div>

        <ul className={styles.navigation}>
            {props.data.map((value, index) =>
                <li key={index}>
                    <Link to={'/' + value.url} activeClassName={styles.active}>{value.name}</Link>
                </li>
            )}
        </ul>
        <a className={styles.button} href="https://www.sts.pl/">Przejdź do sts.pl</a>
        <div className={`${styles.hamburger} ${props.menu.menuOpen ? styles.is_active : ''}`}
             onClick={(e) => {
                e.preventDefault();
                props.menu.setMenuOpen(!props.menu.menuOpen)
            }}>
            <div className={styles.hamburger_box}>
                <div className={styles.hamburger_inner}></div>
            </div>
        </div>
    </div>
}
import React from "react";
import classnames from 'classnames';
import Scroll from "react-scroll";
import {href} from "../../../../helpers/Link";
import styles from './item.module.scss';

export default function SubLink({title, onClick, isActive}) {
    return <Scroll.Link
        className={classnames(
            styles.link,
            {[styles.active]: isActive === title}
        )}
        to={title ? href(title) : ''}
        spy
        smooth
        offset={-80}
        onClick={() => {
            onClick(title);
        }}
    >
        {title}
    </Scroll.Link>
}
import React, { useState } from "react";
import SubLink from "./item";
import styles from './index.module.scss'

export default function Links({data}) {
    const [isActive, handleActive] = useState(`${data[0].text}`);
    return <div className={styles.links}>
        {data.map((value, key) =>
            <SubLink
                key={key}
                title={value.text}
                onClick={(title) => handleActive(title)}
                isActive={isActive}
            />
        )}
    </div>
}
import React from "react";
import styles from "./index.module.scss";

export default function TextHighlight({data}) {
  const nonUpperCase = [
    "Czy ktoś poniżej 18 roku życia gra w STS?",
    "Co możesz zrobić?",
    "Zmiana ustanowionych limitów",
  ];
  if (nonUpperCase.find((text) => text === data)) {
    console.log(data);
    return <span className={styles.text_highlightSmall}>{data}</span>;
  }
  return <span className={styles.text_highlight}>{data}</span>;
}

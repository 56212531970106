import React from "react";
import styles from "./item.module.scss";
import { graphql, StaticQuery } from "gatsby";
import GatsbyImage from "gatsby-image";
import classnames from "classnames";

export default function Banner({ data, firstText }) {
  return (
    <StaticQuery
      query={graphql`
        query {
          desktop: allFile(
            filter: {
              internal: { mediaType: { regex: "/image/" } }
              name: { regex: "/banner.$/" }
            }
          ) {
            edges {
              node {
                relativePath
                childImageSharp {
                  fluid(maxHeight: 400, maxWidth: 900, quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
          mobile: allFile(
            filter: {
              internal: { mediaType: { regex: "/image/" } }
              name: { regex: "/banner._mobile$/" }
            }
          ) {
            edges {
              node {
                relativePath
                childImageSharp {
                  fluid(maxHeight: 440, maxWidth: 750, quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      `}
      render={(results) => {
        const image = results.desktop.edges.find(
          ({ node }) =>
            node.relativePath ===
            data.image.find(({ type }) => type === "desktop").src
        );
        const mobileImage = results.mobile.edges.find(
          ({ node }) =>
            node.relativePath ===
            data.image.find(({ type }) => type === "mobile").src
        );
        const sources = [
          image.node.childImageSharp.fluid,
          {
            ...mobileImage.node.childImageSharp.fluid,
            media: `(max-width: 767px)`,
          },
        ];
        return (
          <li className={styles.banner}>
            <div className={styles.background} />
            <div
              className={classnames(styles.section, {
                [styles.order__2]: firstText,
                [styles.order__1]: !firstText,
              })}
            >
              <GatsbyImage
                className={styles.img_wrapper}
                tag="img"
                fluid={sources}
                alt="banner"
              />
            </div>
            <div
              className={classnames(styles.section, {
                [styles.order__2]: !firstText,
                [styles.order__1]: firstText,
              })}
            >
              <div className={styles.description}>
                <h2 className={styles.title}>{data.title}</h2>
                <span className={styles.desc}>{data.desc}</span>
              </div>
            </div>
          </li>
        );
      }}
    />
  );
}

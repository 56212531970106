import React from "react";
import styles from './menu.module.scss';
import MenuItem from "./item";


export default function Menu({ data, menuOpen, setMenuOpen, mobileAppNavigationActive }) {
    return <ul
        className={`${styles.menu} ${menuOpen ? styles.open : ''} ${mobileAppNavigationActive ? styles.app : ''}`}
        >
        {data.map((value, key) => <MenuItem key={key} data={value} setMenuOpen={setMenuOpen}/>)}
        {
            !mobileAppNavigationActive
            && (
                <li className={styles.menu_item}>
                    <a className={styles.button} href="https://sts.pl">Przejdź do sts.pl</a>
                </li>
            )
        }
    </ul>
}
import Title from "../components/head/modules/title";
import Text from "../components/head/modules/text";
import TextHighlight from "../components/head/modules/text_higlight";
import Buttons from "../components/head/modules/buttons";
import Links from "../components/head/modules/links";
import React from "react";

export function map(data, key) {
    switch (data.type) {
        case "title":
            return <Title key={key} data={data.data} />;
        case "text":
            return <Text key={key} data={data.data} />;
        case "text_highlight":
            return <TextHighlight key={key} data={data.data} />;
        case "links":
            return <Links key={key} data={data.data} />;
        case "buttons":
            return <Buttons key={key} data={data.data} />;
        default:
            throw new TypeError();
    }
}
import React, { useEffect } from "react";
import { Link, navigate } from "gatsby";
import styles from './mobile_app_navigation.module.scss';
import { MOBILE_APP_NAVIGATION } from "../../config";
import { useLocation } from '@reach/router';

const MobileAppNavigation = (props) => {
    const location = useLocation();


    const handleClick = (evt) => {
        // const test3 = document.getElementById('test3');
        // return test3.addEventListener('click', () => { window.location.href = 'pl-sts-betapp://open_page?homePage' });
        evt.preventDefault();
        window.location.href = 'pl-sts-betapp://open_page?homePage';
    }

    // const toggleButton = document.getElementById("test3"); // Get the element
    // if (toggleButton) {
    //     toggleButton.onclick = function() {
    //         return handleClick();
    //     }
    //     toggleButton.addEventListener("click", handleClick());
    // }
    // document.getElementByClassName('dupa').addEventListener('click', () => window.location.href = 'pl-sts-betapp://open_page?homePage');
    return <div className={styles.header}>
        <div className={styles.shape}>
            {
                !props.menu.menuOpen
                && (
                    <a
                        href={location.pathname === '/' ? `${MOBILE_APP_NAVIGATION.backLink}` : '#0'}
                        onClick={(evt) => location.pathname === '/'
                            ? handleClick(evt)
                            : (
                                evt.preventDefault(),
                                window.history.go(-1)
                            )}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#8E9CB7"><path d="M0 0h24v24H0z" fill="none"/><path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z"/></svg>
                        <span>Powrót</span>
                    </a>
                )
            }
            {/* <a
                href={`${MOBILE_APP_NAVIGATION.backLink}`}
            >
                  Test 1   |
            </a>
            <span
                onClick={() => window.location.href = 'pl-sts-betapp://open_page?homePage'}
            >
                Test 2
            </span> */}
        </div>

        <ul className={styles.navigation}>
            {props.data.map((value, index) =>
                <li key={index}>
                    <Link to={'/' + value.url} activeClassName={styles.active}>{value.name}</Link>
                </li>
            )}
        </ul>
        <a className={styles.button} href="https://www.sts.pl/">Przejdź do sts.pl</a>
        <div className={`${styles.hamburger} ${props.menu.menuOpen ? styles.is_active : ''}`}
             onClick={(e) => {
                e.preventDefault();
                props.menu.setMenuOpen(!props.menu.menuOpen)
            }}>
            <div className={styles.hamburger_box}>
                <div className={styles.hamburger_inner}></div>
            </div>
        </div>
    </div>
}

export default MobileAppNavigation;

import React from "react";
import map from 'lodash/map';
import classnames from 'classnames';
import styles from './footer.module.scss';


export const COLUMN_1 = [
    {
        text: 'Oferta',
        link: '',
        class: 'header',
    },
    {
        text: 'Zakłady bukmacherskie',
        link: 'https://www.sts.pl',
        class: 'subheader',
    },
    {
        text: 'Zakłady live',
        link: 'https://www.sts.pl/pl/zaklady-live/',
        class: 'subheader',
    },
    {
        text: 'Sporty wirtualne',
        link: 'https://www.sts.pl/pl/sporty-wirtualne/',
        class: 'subheader',
    },
    {
        text: 'Betgames',
        link: 'https://www.sts.pl/pl/betgames/',
        class: 'subheader',
    },
    {
        text: 'Esport',
        link: 'https://www.sts.pl/pl/esport/',
        class: 'subheader',
    },
    {
        text: 'Promocje',
        link: 'https://www.sts.pl/pl/bonusy/',
        class: 'subheader',
    },
    {
        text: 'Wyniki sportowe',
        link: 'https://www.sts.pl/pl/wyniki/',
        class: 'subheader',
    },{
        text: 'Statystyki',
        link: 'https://stats.sts.pl',
        class: 'subheader',
    }
];

export const COLUMN_2 = [
    {
        text: 'Produkty',
        link: '',
        class: 'header',
    },
    {
        text: 'Szybka rejestracja',
        link: 'https://www.sts.pl/pl/rejestracja-w-sts/',
        class: 'subheader',
    },
    {
        text: 'Błyskawiczne płatności',
        link: 'https://www.sts.pl/pl/top-menu/platnosci/',
        class: 'subheader',
    },
    {
        text: 'Cash-out',
        link: 'https://www.sts.pl/pl/produkty/#cash-out',
        class: 'subheader',
    },
    {
        text: 'Bezpieczny zakład',
        link: 'https://www.sts.pl/pl/produkty/#bezpieczny-zaklad',
        class: 'subheader',
    },
    {
        text: 'Szybka wypłata',
        link: 'https://www.sts.pl/pl/top-menu/platnosci/',
        class: 'subheader',
    },
    {
        text: 'STS TV',
        link: 'https://www.sts.pl/pl/sts-tv/',
        class: 'subheader',
    },
];

export const COLUMN_3 = [
    {
        text: 'Informacje',
        link: '',
        class: 'header',
    },
    {
        text: 'Centrum Klienta',
        link: 'https://www.sts.pl/pl/customer-service/faq/',
        class: 'subheader',
    },
    {
        text: 'Strefa VIP',
        link: 'https://www.sts.pl/pl/strefa-vip/',
        class: 'subheader',
    },
    {
        text: 'Odpowiedzialna gra',
        link: 'https://www.sts.pl/pl/customer-service/odpowiedzialna-gra/',
        class: 'subheader',
    },
    {
        text: 'Polityka prywatności',
        link: 'https://www.sts.pl/userdata/files/regulaminy/polityka-prywatnosci.pdf',
        class: 'subheader',
    },
    {
        text: 'Regulamin',
        link: 'https://www.sts.pl/userdata/files/regulaminy/regulamin-zakladow-online.pdf',
        class: 'subheader',
    },
    {
        text: 'Regulamin archiwalny (do 11.05.2020)',
        link: 'https://www.sts.pl/userdata/files/regulaminy/regulamin-zakladow-online-archiwalny-pl.pdf',
        class: 'subheader',
    },
];

export const COLUMN_4 = [
    {
        text: 'O firmie',
        link: '',
        class: 'header',
    },
    {
        text: 'Informacje o STS',
        link: 'https://www.sts.pl/pl/footmenu/o-sts/o-firmie/',
        class: 'subheader',
    },
    {
        text: 'Lokale STS',
        link: 'https://www.sts.pl/pl/top-menu/lokale-sts/',
        class: 'subheader',
    },
    {
        text: 'Kariera',
        link: 'https://kariera.sts.pl',
        class: 'subheader',
    },
    {
        text: 'O fundacji',
        link: 'https://www.sts.pl/pl/footmenu/o-sts/fundacja-sport-twoja-szansa/',
        class: 'subheader',
    },
    {
        text: 'Dla mediów',
        link: 'https://www.sts.pl/pl/materialy-dla-mediow/',
        class: 'subheader',
    },
];

export const FOOTER_MOBILE = [
    {
        text: 'Informacje',
        link: '',
        class: 'header',
    },
    {
        text: 'Polityka prywatności',
        link: 'https://www.sts.pl/userdata/files/regulaminy/polityka-prywatnosci.pdf',
        class: 'subheader',
    },
    {
        text: 'Regulamin',
        link: 'https://www.sts.pl/userdata/files/regulaminy/regulamin-zakladow-online.pdf',
        class: 'subheader',
    },
    {
        text: 'Regulamin archiwalny (do 11.05.2020)',
        link: 'https://www.sts.pl/userdata/files/regulaminy/regulamin-zakladow-online-archiwalny-pl.pdf',
        class: 'subheader',
    },
    {
        text: 'Odpowiedzialna gra',
        link: 'https://www.sts.pl/pl/customer-service/odpowiedzialna-gra/',
        class: 'subheader',
    },
    {
        text: 'Kariera',
        link: 'https://kariera.sts.pl',
        class: 'subheader',
    },
    {
        text: 'Kontakt',
        link: 'https://www.sts.pl/pl/customer-service/kontakt/',
        class: 'subheader',
    },
];

export default function Footer() {
    return <footer className={classnames(styles.footer)}>
        <div className={styles.wrapper}>
            <div className={styles.container}>
                <div className={styles.column}>
                    <ul className={styles.list}>
                        {
                            map(COLUMN_1, (item) => {
                                return (
                                <li>
                                    <a href={item.link}>
                                        <span className={styles[`item__${item.class}`]}>
                                            {item.text}
                                        </span>
                                    </a>
                                </li>
                            )})
                        }
                    </ul>
                </div>
                <div className={styles.column}>
                    <ul className={styles.list}>
                        {
                            map(COLUMN_2, (item) => {
                                return (
                                <li>
                                    <a href={item.link}>
                                        <span className={styles[`item__${item.class}`]}>
                                            {item.text}
                                        </span>
                                    </a>
                                </li>
                            )})
                        }
                    </ul>
                </div>
                <div className={styles.column}>
                    <ul className={styles.list}>
                        {
                            map(COLUMN_3, (item) => {
                                return (
                                <li>
                                    <a href={item.link}>
                                        <span className={styles[`item__${item.class}`]}>
                                            {item.text}
                                        </span>
                                    </a>
                                </li>
                            )})
                        }
                    </ul>
                </div>
                <div className={styles.column}>
                    <ul className={styles.list}>
                        {
                            map(COLUMN_4, (item) => {
                                return (
                                <li>
                                    <a href={item.link}>
                                        <span className={styles[`item__${item.class}`]}>
                                            {item.text}
                                        </span>
                                    </a>
                                </li>
                            )})
                        }
                    </ul>
                </div>
                <div className={classnames(styles.column, styles.mobile)}>
                    <ul className={styles.list}>
                        {
                            map(FOOTER_MOBILE, (item) => {
                                return (
                                <li>
                                    <a href={item.link}>
                                        <span className={styles[`item__${item.class}`]}>
                                            {item.text}
                                        </span>
                                    </a>
                                </li>
                            )})
                        }
                    </ul>
                </div>
            </div>
        </div>
        <div className={styles.info}>
            <div className={styles.info__container}>
                <div className={styles.note}>
                    <p>
                        2021 © STS S.A. www.sts.pl. Zakaz wstępu na stronę internetową i uczestnictwa w grze hazardowej dla osób poniżej 18 roku życia.
                    </p>
                </div>
            </div>
        </div>
    </footer>
}
import React from "react";
import {StaticQuery, graphql} from "gatsby";
import styles from './index.module.scss';
import GatsbyImage from "gatsby-image";

export default function Head(props) {
    return (
    <StaticQuery
        query={graphql`
            query {
              desktop: allFile(filter: {name: {in: "head"}}) {
                edges {
                  node {
                    relativePath
                    childImageSharp {
                      fluid(maxHeight: 500, maxWidth: 1920, quality: 100) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
              }
              mobile: allFile(filter: {name: {in: "head_mobile"}}) {
                edges {
                  node {
                    relativePath
                    childImageSharp {
                      fluid(maxHeight: 440, maxWidth: 750, quality: 100) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
              }
            }
        `}
        render={results => {
            const image = results.desktop.edges.find(
                ({node}) => node.relativePath === props.background.find(({type})  => type === "desktop").src
            );
            const mobileImage = results.mobile.edges.find(
                ({node}) => node.relativePath === props.background.find(({type})  => type === "mobile").src
            );
            const sources = [
                image.node.childImageSharp.fluid,
                {
                    ...mobileImage.node.childImageSharp.fluid,
                    media: `(max-width: 992px)`
                }
            ]
            return (
                <div className={styles.wrapper2} style={{position: 'relative'}}>
                    <GatsbyImage className={styles.img_wrapper} fluid={sources} />
                    <header className={styles.wrapper}>
                        <div className={styles.container}>
                            <div className={styles.description}>
                                {props.children}
                            </div>
                        </div>
                    </header>
                </div>
        )}}
        />
    )
}
import React, {useState} from "react";
import {Helmet} from "react-helmet";
import Navigation from "../components/navigation";
import Head from "../components/head";
import {map as sectionMap} from "../helpers/Section";
import {map as headMap} from "../helpers/Head";
import {href} from "../helpers/Link";
import styles from './index.module.scss';
import '../styles/main.scss';
import Footer from "../components/footer";
import Menu from "../components/menu";
import { MOBILE_APP_NAVIGATION } from '../config';
import MobileAppNavigation from "../components/mobile_app_navigation";


export default function Index(props) {
    const [menuOpen, setMenuOpen] = useState(false);
    const pageData = props.pageContext.pageData;
    const navigationData = props.pageContext.navigationData;


    return <div className={`${menuOpen ? styles.open_menu : ''}`}>
        <Helmet title={pageData.document.title}>
            <html lang={pageData.document.lang} />
            <meta name="description" content={pageData.document.description}/>
            <meta property="og:image" content="https://www.sts.pl/logo_seo.png"/>
            <meta property="og:description" content="STS.pl - Legalny polski bukmacher Załóż konto i odbierz swój bonus startowy 1200 zł! Wejdź z nami w świat Zakładów na Żywo z funkcją podglądów boiska. Kontroluj przebieg gry i obstawiaj do ostatniego gwizdka!"/>
            <meta property="og:title" content="STS - Bukmacherskie Zakłady Sportowe"/>
            <meta property="og:image:secure_url" content="https://www.sts.pl/logo_seo.png"/>
        </Helmet>
        {
            MOBILE_APP_NAVIGATION.isActive
            ? <MobileAppNavigation
            data={navigationData}
            menu={{ menuOpen, setMenuOpen }}
            />
            : <Navigation
                data={navigationData}
                menu={{ menuOpen, setMenuOpen }}
            />
        }
        <Menu
            data={navigationData}
            menuOpen={menuOpen}
            setMenuOpen={setMenuOpen}
            mobileAppNavigationActive={MOBILE_APP_NAVIGATION.isActive}
        />
        <main>
        <Head background={pageData.head.background}>
            {pageData.head.blocks.map((value, key) => headMap(value, key))}
        </Head>
        {
            pageData.sections.map((value, key) =>
                <div className={styles.sections} key={key} id={value.title ? href(value.title) : null}>
                    {value.blocks.map((block, key) => sectionMap(block, key))}
                    {value.title !== undefined && value.title !== '' && <div className={styles.sections_background}/> }
                </div>
            )
        }
        </main>
        {
            !MOBILE_APP_NAVIGATION.isActive && <Footer/>
        }
    </div>
}
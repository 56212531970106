import React from "react";
import styles from "./text.module.scss";

export default function Text({data}) {
  if (
    data ===
      "Przygotowaliśmy dla Ciebie zestaw porad dotyczących odpowiedzialnej gry." ||
    data ===
      "Oto zestaw przykładów zachowań, które mogą sugerować, że Twoja gra wykracza poza rozrywkę." ||
    data ===
      "Jeśli obawiasz się, że członek rodziny lub przyjaciel zmaga się z trudnościami związanymi z nadmierną grą, zwróć uwagę na poniższe sygnały ostrzegawcze:"
  ) {
    return (
      <div>
        <p className={styles.textAfterHeader}>{data}</p>
        <br></br>
      </div>
    );
  }
  return <p className={styles.textAfterHeader}>{data}</p>;
}

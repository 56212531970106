import React, {useState} from "react";
import styles from "./menuItem.module.scss";
import {Link} from "gatsby";
import arrow_icon from "./../../../static/images/chevron.svg";

export default function MenuItem({data, setMenuOpen}) {
  let url = "";
  if (typeof window !== "undefined") {
    url = sessionStorage.getItem("url");
  }
  const [isOpen, setOpen] = useState(false);
  const [isActive, handleActive] = useState(url);
  const mainUrl = "/" + data.url;

  return (
    <li className={styles.menu_item}>
      <h2 className={styles.title}>
        <Link
          to={mainUrl}
          activeClassName={styles.active}
          onClick={(e) => {
            if (data.links.length > 0) {
              e.preventDefault();
              setOpen(!isOpen);
            }
          }}
        >
          {data.name}
          {data.links.length > 0 && (
            <span
              className={`${styles.icon} ${isOpen ? styles.icon_open : ""}`}
            >
              <img src={arrow_icon} alt="arrow icon" />
            </span>
          )}
        </Link>
      </h2>
      <ul className={`${styles.list} ${isOpen ? styles.list_open : ""}`}>
        {data.links.map((value, key) => (
          <li key={key} className={styles.list_item}>
            <Link
              to={mainUrl + "#" + value.url}
              activeClassName={styles.active}
              className={`${value.url === isActive ? styles.active : ""}`}
              onClick={() => {
                sessionStorage.setItem("url", value.url);
                handleActive(value.url);
                setMenuOpen(false);
              }}
            >
              {value.name}
            </Link>
          </li>
        ))}
      </ul>
    </li>
  );
}

import React from 'react';
import Title from "../components/title";
import Text from "../components/text";
import ListIcon from "../components/list_icon";
import Boxes from "../components/boxes";
import Banners from "../components/banners";
import TextHighlight from "../components/text_higlight";
import List from "../components/list";
import Separate from "../components/separate";

export function map(data, key) {
    switch (data.type) {
        case "title":
            return <Title key={key} text={data.data} />;
        case "text":
            return <Text key={key} data={data.data} />;
        case "text_highlight":
            return <TextHighlight key={key} data={data.data} />;
        case "list":
            return <List key={key} data={data.data} />;
        case "list_icon":
            return <ListIcon key={key} data={data.data} />;
        case "boxes":
            return <Boxes key={key} data={data.data} />;
        case "banners":
            return <Banners key={key} data={data.data} />;
        case "separate":
            return <Separate key={key} />;
        default:
            throw new TypeError();
    }
}